import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.url = this.element.dataset.url
    window.Ajax.get(this.url, this.ajaxOptions);
  }

  handleSuccess(responseText){
    let responseJson = JSON.parse(responseText);
    this.pieChart(this.element, responseJson.series, responseJson.categories)
  }

  handleFail(responseText){
    console.error('[ERROR]', responseText)
  }

  handleDone(){
  }

  get ajaxOptions(){
    return {
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ],
    }
  }

  pieChart(myElement, dataSeries, dataCategories){
    var options = {
      series: dataSeries,
      chart: {
        type: 'bar',
        stacked: true,
        height: 350,
        stackType: '100%',
        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories: dataCategories,
        labels: {
          show: false,
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return parseInt(value)
          }
        }
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return `${parseInt(value)} Orang`
          }
        }
      },
      fill: {
        opacity: 1
      },
      stroke: {
        show: true,
        width: 1
      },
      legend: {
        show: true,
        position: 'right',
      },
      theme: {
        mode: 'dark'
      }, 
      colors: ["#E08B1F", "#E0481F", "#734D35"]
    };

    var chart = new ApexCharts(myElement, options);
    chart.render();
  }
}