import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    $(this.element).on('ajax:success', function(response){
      this.handleSuccess(response);
    }.bind(this)).on('ajax:error',function(response){
      this.handleFail(response);
    }.bind(this));
  }

  handleSuccess(response){
    const json_response = JSON.parse(response.detail[2].response)

    if (json_response.notice){
      FlashV2.show("success", json_response.notice)
    }
      
    if (json_response.location_url){
      setTimeout(function(){
        location.href = json_response.location_url  
      }, 2000);
    }

    if (json_response.confirmation){  
      let tmp = document.createElement("div");
      tmp.innerHTML = json_response.modal_confirmation
      let body = document.querySelector("body")
      
      if (body.querySelector("#confirmation_modal")){
        body.querySelector("#confirmation_modal").remove()
      }
      body.append(tmp.firstElementChild);

      $("#modal-login").modal('hide');
      $("#confirmation_modal").modal('show');
    }
  }

  handleFail(response){
    const json_response = JSON.parse(response.detail[2].response)
    FlashV2.show("danger", json_response.alert)
  }
}
