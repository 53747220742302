import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 25,
        type: 'number',
        selector: false,
        textAlign: 'center',
        template: function(data) {
          return `<span class="font-weight-bolder text-white">${data.index}</a>`;
        }
      },
      {
        field: 'title',
        title: 'Title',
        width: 350,
        autoHide: false,
        template: function(data) {
          return `<span class="font-weight-bolder text-white">${data.title}</a>`;
        }
      },
      {
        field: 'description',
        title: 'Deskripsi',     
        width: 250,   
        autoHide: true,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.description}</span>`;
        }
      },
      {
        field: 'speaker',
        title: 'Pembicara',
        template: function(data) {
          return `
          <span class="font-weight-bolder">${data.speaker}</span>
          `;
        }
      },
      {
        field: 'date',
        title: 'Tanggal Webinar',
        autoHide: false,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.date}</span>`;
        }
      },
      {
        field: 'capacity',
        title: 'Peserta/Kuota',
        autoHide: false,
        template: function(data) {
          return `
          <span class="font-weight-bolder">${data.quota_reached}/${data.capacity}</span>
          `;
        }
      },
      {
        field: 'status',
        title: 'Status',
        autoHide: false,
        template: function(data) {
          if(data.status) {
            return `<label class="label label-inline label-success font-weight-bold">Mengikuti</label>`;
          }else{
            return `<label class="label label-inline label-warning font-weight-bold">Terdaftar</label>`;
          }
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 120,
        overflow: 'visible',
        autoHide: false,
        template: function(data) {
          return `            
            <a class="btn btn-sm btn-clean btn-icon" href="${data.show_path}" title="Tampilkan">
              <i class="fa fa-eye text-primary"></i>
            </a>
          `;
        },
      }
    ]
  }
}
