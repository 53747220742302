import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    $(this.element).on('ajax:success', function(response){
      this.handleSuccess(response);
    }.bind(this)).on('ajax:error',function(response){
      this.handleFail(response);
    }.bind(this));
  }

  handleSuccess(response){
    const json_response = JSON.parse(response.detail[2].response)
    
    let tmp = document.createElement("div");
    tmp.innerHTML = json_response.modal_confirmation
    let body = document.querySelector("body")
    
    if (body.querySelector("#confirmation_modal")){
      body.querySelector("#confirmation_modal").remove()
    }
    body.append(tmp.firstElementChild);

    $("#modal-register").modal('hide');
    $("#confirmation_modal").modal('show');
    FlashV2.show("danger", json_response.notice)
  }

  handleFail(response){
    const json_response = JSON.parse(response.detail[2].response)
    FlashV2.show("danger", json_response.alert)

    // let tmp = document.createElement("div");
    // tmp.innerHTML = json_response.modal_confirmation
    // let body = document.querySelector("body")
    // if (document.querySelector("#confirmation_modal")){
    //   document.querySelector("#confirmation_modal").remove()
    // }
    // body.append(tmp.firstElementChild);

    // $("#modal-register").modal('hide');
    // $("#confirmation_modal").modal('show');
  }
}
