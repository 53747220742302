import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 25,
        type: 'number',
        selector: false,
        textAlign: 'center',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'name',
        title: 'Nama Materi',
        width: 200,
        autoHide: false,
        textAlign: 'left',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.name}</span>`;
        }
      },
      {
        field: 'exam_time',
        title: 'Lama Ujian',
        sortable: false,
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.exam_time}</span>`;
        }
      },
      {
        field: 'exam_minimum_score',
        title: 'Nilai Minimal Ujian',
        sortable: false,
        width: 200,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.exam_minimum_score}</span>`;
        }
      },
      {
        field: 'exam_score',
        title: 'Nilai Ujian',
        sortable: false,
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.exam_score}</span>`;
        }
      },
      {
        field: 'status',
        title: 'status',
        sortable: false,
        autoHide: false,
        width: 120,
        template: function(data) {
          if(data.status) {
            return `<label class="label label-inline label-success font-weight-bold">Lulus</label>`;
          }else{
            return `<label class="label label-inline label-danger font-weight-bold">Belum Lulus</label>`;
          }
        }
      }
      // ,
      // {
      //   field: 'Actions',
      //   title: 'Actions',
      //   sortable: false,
      //   width: 200,
      //   overflow: 'visible',
      //   autoHide: false,
      //   template: function(data) {
      //     return `
      //       <a class="btn btn-sm btn-primary mr-2" href="${data.show_detail}" title="Detail">
      //         <i class="fa fa-eye mr-1"></i>
      //         Lihat Detail
      //       </a>
      //     `
      //   },
      // }
    ]
  }
}
