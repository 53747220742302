import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 25,
        type: 'number',
        selector: false,
        textAlign: 'center',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'name',
        title: 'Nama Video',
        width: 310,
        autoHide: false,
        textAlign: 'left',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.name}</span>`;
        }
      },
      {
        field: 'progress',
        title: 'Progress Video (%)',
        sortable: false,
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.progress}</span>`;
        }
      },
      {
        field: 'total_duration',
        title: 'Total Durasi Video',
        sortable: false,
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.total_duration}</span>`;
        }
      },
      {
        field: 'status',
        title: 'status',
        sortable: false,
        autoHide: false,
        width: 100,
        template: function(data) {
          if(data.status) {
            return `<label class="label label-inline label-success font-weight-bold">Selesai</label>`;
          }else{
            return `<label class="label label-inline label-warning font-weight-bold">Belum Selesai</label>`;
          }
        }
      }
      // ,
      // {
      //   field: 'Actions',
      //   title: 'Actions',
      //   sortable: false,
      //   width: 200,
      //   overflow: 'visible',
      //   autoHide: false,
      //   template: function(data) {
      //     return `
      //       <a class="btn btn-sm btn-primary mr-2" href="${data.show_action}" title="Download">
      //         <i class="fa fa-eye mr-1"></i>
      //         Lihat Detail
      //       </a>
      //     `;
      //   },
      // }
    ]
  }
}
