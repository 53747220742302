import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.injectBackgroundImage();
  }

  injectBackgroundImage(){
    if(window.screen.width >= 1440){
      this.element.style.backgroundImage = `url(${this.element.dataset.xhd})`;
    } else if(window.screen.width >= 1024){
      this.element.style.backgroundImage = `url(${this.element.dataset.hd})`;
    } else if(window.screen.width >= 768){
      this.element.style.backgroundImage = `url(${this.element.dataset.sd})`;
    } else if(window.screen.width >= 320){
      this.element.style.backgroundImage = `url(${this.element.dataset.md})`;
    }
  }
}

