import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    window.setTimeout(this.submitForm.bind(this),500);
  }

  submitForm(){
    $("#submit-frm-conf").submit();
  }
}
