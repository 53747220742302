import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    $(function () {
      $("#loadings-layer").addClass('hidden');
    })
  }
  
  connect(){
    window.setTimeout(this.tailwind_config.bind(this),500);
  }

  tailwind_config(){
    tailwind.config = {
      variants: {
        extend: {
          translate: ['group-hover', 'hover'],
        },
      },
      theme: {
        extend: {
          colors: {
            taxsam: {
              DEFAULT: '#242424',
              border: '#353333',
              warning: '#FFA800',
              danger: '#dc3545',
              orange: '#EE6123'
            }
          }
        }
      }
    }
  }
}