import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.url = this.element.dataset.url
    // window.KTApp.block(this.element.nextElementSibling, {});    
    window.Ajax.get(this.url, this.ajaxOptions);
  }

  handleSuccess(responseText){
    let responseJson = JSON.parse(responseText);
    // window.KTApp.unblock(this.element.nextElementSibling, {});
    this.pieChart(this.element, responseJson.series, responseJson.categories)
  }

  handleFail(responseText){
    console.error('[ERROR]', responseText)
    // window.KTApp.unblock(this.element.nextElementSibling, {});
  }

  handleDone(){
  }

  get ajaxOptions(){
    return {
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ],
    }
  }

  pieChart(myElement, dataSeries, dataCategories){
    var options = {
      series: dataSeries,
      chart: {
        type: 'pie',
        height: 500,
        width: 500
      },
      labels: dataCategories,
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 400
          },
          legend: {
            position: 'left'
          }
        }
      }],
      tooltip: {
        y: {
          formatter: function(value) {
            return `${value} Orang`;
          }
        }
      },
      theme: {
        // mode: 'dark', 
        // palette: 'palette9', 
        monochrome: {
            enabled: true,
            color: '#9d9d9d',
            shadeTo: 'dark',
            shadeIntensity: 0.80
        },
      }
      // ,
      // colors: [
      //   "#fed7aa",
      //   "#fdba74",
      //   "#fb923c",
      //   "#f97316",
      //   "#ea580c",
      //   "#c2410c",
      //   "#9a3412",
      //   "#7c2d12",
      //   "#431407",
      // ]
    };

    var chart = new ApexCharts(myElement, options);
    chart.render();
  }
}