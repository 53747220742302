export default class FlashV2 {
  static show(type, message){
    const html = this.html(type, message);
    const currentFlash = document.querySelector('#js-Flash-v2');
    if(currentFlash){
      window.Util.removeElement(currentFlash);
    }

    document.body.insertAdjacentHTML('afterbegin', this.html(type, message));
  }

  static html(type, message){
    return `
      <div
        id="js-Flash-v2"
        class="${this.typeClass(type)} border px-4 py-3 rounded relative"
        style="position:fixed;top:0;left:0;width:100%;z-index:1051;"
        role="alert"
        data-controller="flash-v2"
      >
        <strong class="font-bold">
          <i class="${this.iconClass(type)}"></i>
        </strong>
        <span class="block sm:inline">
          ${message}
        </span>
        <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
          <svg
            data-action="click->flash-v2#close"
            class="fill-current h-6 w-6 ${this.typeClassCloseButton}"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path
              d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
            />
          </svg>
        </span>
      </div>
    `;
  }

  static typeClass(type){
    if(type === 'danger'){
      return 'bg-red-100 border-red-400 text-red-700';
    }
    if(type === 'success'){
      return 'bg-teal-100 border-teal-400 text-teal-700';
    }
    if(type === 'primary'){
      return 'bg-blue-100 border-blue-400 text-blue-700';
    }
    if(type === 'warning'){
      return 'bg-orange-100 border-orange-400 text-orange-700';
    }
  }
  static typeClassCloseButton(type){
    if(type === 'danger'){
      return 'text-red-500';
    }
    if(type === 'success'){
      return 'text-teal-500';
    }
    if(type === 'primary'){
      return 'text-blue-500';
    }
    if(type === 'warning'){
      return 'text-orange-500';
    }
  }
  static iconClass(type){
    if(type === 'danger'){
      return 'fas fa-exclamation-triangle';
    }
    if(type === 'success'){
      return 'fas fa-check-circle';
    }
    if(type === 'primary'){
      return 'fas fa-exclamation-triangle';
    }
    if(type === 'warning'){
      return 'fas fa-exclamation-triangle';
    }
  }
}
