import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.url = this.element.dataset.url
    // window.KTApp.block(this.element, {});    
    window.Ajax.get(this.url, this.ajaxOptions);
  }

  handleSuccess(responseText){
    let responseJson = JSON.parse(responseText);
    // window.KTApp.unblock(this.element, {});
    this.barChart(this.element, responseJson.series, responseJson.categories)
  }

  handleFail(responseText){
    console.error('[ERROR]', responseText)
    // window.KTApp.unblock(this.element, {});
  }

  handleDone(){
  }

  get ajaxOptions(){
    return {
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ],
    }
  }

  barChart(myElement, dataSeries, dataCategories){
    var options = {
      series: dataSeries,
      chart: {
        id: "Grafik",
        type: 'line',
        height: 400,
        stacked: false,
        zoom: {
          enabled: false
        },
        selection: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        show: true,
        width: 1
      },
      xaxis: {
        categories: dataCategories,
        labels: {
          show: false,
        }
      },
      yaxis: {
        forceNiceScale: true,
        min: 0,
        max: 100,
        tickAmount: 10,
        labels: {
          formatter: function (value) {
            if(parseInt(value)){
              return this.formatLabel(value);
            }else{
              return value
            }
          }.bind(this)
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return this.formatLabel(parseInt(value));
          }.bind(this)
        }
      },
      theme: {
        mode: 'dark', 
        monochrome: {
            enabled: true,
            color: '#e95900',
            shadeTo: 'dark',
            shadeIntensity: 0.80
        },
      }
    };

    var chart = new ApexCharts(myElement, options);
    chart.render();
  }

  formatLabel(value){    
    return `${value}`;
  }
}
