import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    $(this.element.querySelectorAll('input')).on('change', function(e){
      this.performRequest(e)
    }.bind(this));

    const xtml = document.querySelector("#membership_and_payment")
    if (xtml.innerHTML.length < 10) {
      this.element.querySelectorAll('input')[0].click();  
    }
  }


  performRequest(input){
    window.KTApp.blockPage(this.element, {});
    window.Ajax.get(input.currentTarget.dataset.url, this.ajaxOptions());
  }

  handleSuccess(response){
    const parsedResponse = JSON.parse(response);
    const xtml = document.querySelector("#membership_and_payment")
    xtml.innerHTML = parsedResponse.partial
  }

  handleFail(response){
    console.log(response)
    Flash.show("danger", "Data Tidak Ditemukan")
    const xtml = document.querySelector("#membership_and_payment")
    xtml.parentElement.querySelectorAll('input')[0].click();
  }

  handleDone(){
    window.KTApp.unblockPage();
  }

  ajaxOptions(dataPost){
    return {
      headers: [
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ],
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this)
    }
  }
}